import { Container } from './Container';

export const Banner = () => {
  const handleScroll = () => {
    window.scrollTo({
      left: 0,
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };

  return (
    <Container>
      <div className="flex gap-44 text-justify">
        <div className="max-w-[562px] w-full font-LeagueSpartan mt-4 md:mt-4">
          <h3 className="text-Irish text-3xl md:text-[45px] leading-9 font-semibold ">
            Read + Reflect + AI
          </h3>
          <div className="text-Irish text-xl md:text-[40px] leading-10 font-[300] my-1 md:my-4">
            Your Formula for Reading{' '}
            <span className="font-medium">Superpowers</span>
          </div>
          <div className="flex justify-between md:items-center">
            <p className="text-DonkeyBrowm text-base md:text-3xl font-[300] mt-2">
              Simply reading isn't enough. To truly gain, You need to reflect on
              its ideas and adapt them to truly learn its value. And that’s
              where 2read app helps.
            </p>
            <div className="w-[1200px] block md:hidden">
              <img
                className="max-w-full h-auto"
                src="/assets/images/4.png"
                alt="Table"
              />
            </div>
          </div>

          <button
            onClick={handleScroll}
            className="w-28 md:w-48 h-10 md:h-[71px] flex justify-center items-center border-2 border-Irish rounded-full text-[15px] md:text-2xl text-Irish mt-2 md:mt-9" style={{ marginTop: '40px'}}
          >
            Download
          </button>
        </div>
        <div className="max-w-[500px] hidden md:block">
          <img
            className="max-w-full h-auto"
            src="/assets/images/4.png"
            alt="Table"
          />
        </div>
      </div>
      <div className="my-10 flex justify-end">
        <div
          className="flex flex-col gap-5 items-center cursor-pointer"
          onClick={handleScroll}
        >
          <span className="inline-block rotate-90 text-2xl text-Irish">
            Scroll
          </span>
          <img
            width={36}
            height={36}
            src="assets/images/downArrow.png"
            alt="Down Arrow"
          />
        </div>
      </div>
    </Container>
  );
};
