import React from 'react';
//import { Container } from 'shared/components';

const listItems = [
  'Kindle Highlights Sync',
  "Collect cherished Kindle Highlights in 'Likes'.",
  'Notification Reminders',
  'Edit, Search, and Share Highlights',
  'Set Reading Goals',
  'Reflect Notes',
  'Export all Highlights & Notes in pdf file, markdown file, and text.',
  'Enhance reading with WordWise, Highlight Insights, and AI Summary.',
];

export const NoteTakingSection = () => {
  return (
    <section className="bg-CyanBlue py-20 px-7 md:px-20">
      <div className="flex justify-between items-center">
        <div className="flex flex-1 justify-start md:pl-16">
          <div className="w-64 hidden md:block">
            <img src="/assets/images/mobile-6.png" alt="Note Taking" />
          </div>
          <div className="w-64 hidden md:block -ml-40">
            <img src="/assets/images/mobile-4.png" alt="Note Taking" />
          </div>
        </div>
        <div className="md:w-[44%] text-justify">
          <h3 className="text-BluePurple text-3xl md:text-5xl font-medium font-LeagueSpartan mb-8">
            Book Shots & More
          </h3>
          <p className="text-xl md:text-2xl text-BluePurple font-LeagueSpartan font-thin mb-8 md:mb-12">
          Don't have Kindle highlights? No worries! Start your journey with Bookshots—your gateway to wisdom from top books on health, wealth, and personal growth. <br />
          <br />
✨ Summaries of best-sellers <br />
🎧 Audio for learning on the go <br />
💡 Key insights to spark curiosity <br />
<br />
Perfect for quick knowledge boosts and finding your next great read. Begin your journey to continuous learning and growth today!
          </p>
          <div className="flex justify-center w-full">
            <div className="w-32 block md:hidden" style={{ position: 'relative', left: '50px', zIndex: '1'}}> {/* Display on mobile */}
              <img src="/assets/images/mobile-6.png" alt="Note Taking"/>
            </div>
            <div className="w-32 block md:hidden mb-4" style={{ position: 'relative', right: '40px', zIndex: '1'}}>
              <img src="/assets/images/mobile-4.png" alt="Note Taking" />
            </div>
          </div>
          <a className="text-BluePurple underline font-medium" href="/#">
            Here's everything 2read app can do for you!
          </a>
          <div className="grid grid-cols-1 md:grid-cols-2 auto-rows-auto gap-4 mt-4">
            {listItems.map((item, index) => (
              <div className="flex items-center text-BluePurple gap-2">
                <img
                  width={33}
                  height={38}
                  src="/assets/images/Checkmark.png"
                  alt="Checkmark"
                />
                <p className="text-sm">{item}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
